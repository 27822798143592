import React from 'react';
import { useLocation } from 'react-router-dom';
import { pageLogo } from './assets';
import { ROUTES } from '../../routes';

export const SignupBackground: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const isCurrentPageCompleteOrder = pathname.includes(ROUTES.COMPLETE_ORDER);

  const wrapperClassName = isCurrentPageCompleteOrder
    ? 'no-x-overflow self-signup'
    : 'self-signup';

  const contentClassName = isCurrentPageCompleteOrder
    ? 'co-page self-signup__content'
    : 'self-signup__content';

  const attrs = {
    wrapper: {
      className: wrapperClassName,
    },

    logo: {
      wrapper: {
        className: 'self-signup__logo',
      },

      img: {
        className: 'self-signup__logo-img',
        src: pageLogo,
      },
    },

    backgroundImg: {
      className: 'self-signup-background-img',
    },

    content: {
      className: contentClassName,
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.backgroundImg} />

      <div {...attrs.content}>{children}</div>
    </div>
  );
};
