import React from 'react';
import loadingIcon from '../../assets/loading-spinner.svg';
import warnIcon from '../../assets/Info.svg';
import successIcon from '../../assets/checkmark-circle.svg';

export enum PaymentButtonState {
  Default = 0,
  Disabled,
  Loading,
  Success,
  Error,
}

type PaymentButtonProps = {
  children: JSX.Element | string;
  onClick?: () => void;
  state?: PaymentButtonState;
};

export const PaymentButton: React.FC<PaymentButtonProps> = ({
  children,
  onClick,
  state,
}: PaymentButtonProps): JSX.Element => {
  let classAttr = 'payment-btn';
  let buttonInner: JSX.Element | string = children;
  let disabled = false;

  switch (state) {
    case PaymentButtonState.Disabled:
      classAttr += ' disabled';
      disabled = true;
      break;
    case PaymentButtonState.Loading:
      classAttr += ' loading';
      buttonInner = <img alt="" src={loadingIcon} />;
      disabled = true;
      break;
    case PaymentButtonState.Success:
      classAttr += ' check';
      buttonInner = <img alt="" src={successIcon} />;
      break;
    case PaymentButtonState.Error:
      classAttr += ' error';
      buttonInner = <img alt="" src={warnIcon} />;
      break;
    default:
      classAttr += ' default';
      break;
  }
  return (
    <button
      className={classAttr}
      onClick={onClick}
      type="submit"
      disabled={disabled}
    >
      {buttonInner}
    </button>
  );
};
