import React from 'react';

type ToggleProps = {
  active?: boolean;
  onToggle: () => void;
  className?: string;
  disabled?: boolean;
  noStatusLabel?: boolean;
  isDark?: boolean;
};

export const Toggle: React.FC<ToggleProps> = ({
  active = false,
  onToggle = () => {},
  className = '',
  disabled = false,
  noStatusLabel = false,
  isDark = false,
}: ToggleProps): JSX.Element => {
  const wrapperClassName = `toggle toggle-${
    active && !disabled ? 'active' : 'inactive'
  } ${isDark ? 'dark' : ''} ${className}`;

  const statusLabelClassName = `toggle__label toggle__label-${
    active && !disabled ? 'active' : 'inactive'
  }`;

  const attrs = {
    wrapper: {
      className: wrapperClassName,
      style: disabled ? { cursor: 'default', opacity: 0.6 } : {},
    },

    input: {
      readOnly: true,
      checked: active,
      className: 'toggle__control',
      type: 'checkbox',
      onClick: onToggle,
    },

    toggle: {
      className: 'toggle__toggle',
    },

    statusLabel: {
      className: statusLabelClassName,
    },
  };

  const statusLabel = noStatusLabel ? null : (
    <span {...attrs.statusLabel}>{active && !disabled ? 'ON' : 'OFF'}</span>
  );

  return (
    <label {...attrs.wrapper}>
      <input {...attrs.input} />
      <div {...attrs.toggle} />
      {statusLabel}
    </label>
  );
};
