export interface PlanInfo {
  productId: string;
  price: {
    monthly: {
      priceId: string;
      price: string;
    };
    yearly: {
      priceId: string;
      price: string;
    };
  };
}

export interface StripePricingData {
  starter: PlanInfo;
  professional: PlanInfo;
  scale: PlanInfo;
}

export interface ChoosedPlanInfo {
  productId: string;
  priceId: string;
  price: string;
  seatsQty: string;
  paymentPeriod: string;
  name: string;
}

export enum ClientType {
  DASHBOARD = 'dashboard',
  SUPER_ADMIN = 'superAdmin',
  EXTENSION = 'extension',
}

export enum UserSignUpFlow {
  COMPLETED = 'completed',
  SELF_SIGNUP = 'selfSignUp',
  INVITE_SIGNUP = 'inviteSignUp',
  SUBSCRIPTION_EXPIRED = 'subscriptionExpired',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRIAL = 'trial',
}

export enum Role {
  ADMIN = 'admin',
  DEALMAKER = 'dealmaker',
  OWNER = 'owner',
  SUPER_ADMIN = 'superAdmin',
}

export enum InviteStatus {
  SENT = 'sent',
  REVOKED = 'revoked',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
}

export enum PaymentStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

export interface Subscription {
  planName: string;
  status: SubscriptionStatus;
  trialEnd: string;
  endedAt: string;
}

export interface App {
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  stats: {
    emailsAnalyzed?: number;
    updatedAt?: string;
    installHistory?: {
      action: string;
      date: string;
      version: string;
    };
  };
}

export interface Organization {
  name: string;
  nameType: string;
  url: string;
  primaryEmail: string;
  avatar: string;
  orgId: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  inviteStatus: 'sent' | 'expired';
  inviteExpires: string;
  location: string | null;
  usersCount: number | string;
  maxUsersCount: number | string;
  ownerName: string;
  adminNames: string[];
  acquisitionSource: string | null;
  timezone: string | null;
  activeUsersCount: number | null;
  inactiveUsersCount: number | null;
  teamsCount: number | null;
  enabledFeatures: string[];
  isFunctionalityEnabled?: boolean;
  subscription: Subscription | null;
  signupType: string;
}

export type User = {
  name: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phoneNumber: string;
  timezone: string;
  title: string;
  userId: string;
  orgId: string;
  email: string;
  apps: App[];
  role: Role;
  lastSignIn: string;
  lastActivity: string;
  createdAt: string;
  updatedAt: string;
  stats: {
    emailsAnalyzed: number;
    updatedAt: string;
  };
  status?: string;
  deleted: boolean;
  active: boolean;
  isEmailVerified: boolean;
  inviteStatus: InviteStatus;
  inviteExpires: string;
  jwt?: string;
  updatedAvatar?: string;
  userHash: string;
  showDashboardWelcomePopup: boolean;
  showInstallExtensionPopup: boolean;
  isChromeExtensionInstalled: boolean;
  metadata?: {
    features?: Array<{
      enabled: boolean;
      isInPackage: boolean;
      name: string;
    }>;
  };
};

export interface GetAuthCodeResponse {
  authCode: string;
}

export interface AuthState {
  isLoading: {
    authCodes: boolean;
    signIn: boolean;
    signOut: boolean;
    identity: boolean;
    uninstallExtension: boolean;
    paymentStatus: boolean;
    trialUsedStatus: boolean;
    verifyInviteToken: boolean;
  };
  isError: {
    authCodes: boolean;
    signIn: boolean;
    signOut: boolean;
    identity: boolean;
    uninstallExtension: boolean;
    paymentStatus: boolean;
    trialUsedStatus: boolean;
    verifyInviteToken: boolean;
  };
  isLoggedIn: boolean;
  isInitialized: boolean;
  dashboardAuthCode: string | null;
  superAdminAuthCode: string | null;
  extensionAuthCode: string | null;
  user: User | null;
  org: Organization | null;
  userSignUpFlow: UserSignUpFlow | null;
  plansAndPricing: StripePricingData;
  choosedPlan: ChoosedPlanInfo | null;
  paymentStatus: PaymentStatus | null;
  trialUsedStatus: boolean;
}
