import React from 'react';
import { attentionIcon, closeIcon } from './assets';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearSignInError } from '../../store/auth/auth.slice';

export const ErrorModal: React.FC = (): JSX.Element => {
  const isSignInError = useAppSelector((state) => state.auth.isError.signIn);
  const isIdentityError = useAppSelector(
    (state) => state.auth.isError.identity
  );

  const dispatch = useAppDispatch();

  const attrs = {
    wrapper: {
      className: 'error-modal',
    },

    overlay: {
      className: 'error-modal-overlay',
    },

    window: {
      wrapper: {
        className: 'error-modal__window',
      },

      closeIcon: {
        src: closeIcon,
        className: 'error-modal__window-close-icon',
        onClick: () => {
          dispatch(clearSignInError());
        },
      },

      title: {
        wrapper: {
          className: 'error-modal__window__title',
        },

        icon: {
          src: attentionIcon,
          className: 'error-modal__window__title-icon',
        },
      },

      content: {
        wrapper: {
          className: 'error-modal__window__content',
        },

        firstWord: {
          className: 'error-modal__window__content-first-word',
        },

        link: {
          className: 'error-modal__window__content-link',
          href: 'mailto:support@substrata.me',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  };

  const content =
    isSignInError || isIdentityError ? (
      <div {...attrs.wrapper}>
        <div {...attrs.overlay} />
        <div {...attrs.window.wrapper}>
          <img {...attrs.window.closeIcon} alt="" />
          <div {...attrs.window.title.wrapper}>
            <img {...attrs.window.title.icon} alt="" /> Could not log you in.
          </div>
          <div {...attrs.window.content.wrapper}>
            <span {...attrs.window.content.firstWord}>This </span>
            email address is either not associated with a user or it has not
            been activated. To get started, please reach out to{' '}
            <a {...attrs.window.content.link}>support@substrata.me</a>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  return content;
};
