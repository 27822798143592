import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';
import { MIX_PANEL_TOKEN, MODE } from '../../constants/config';
import { ROUTES } from '../../routes';
import { initMixpanel } from '../../store/global/global.slice';
import { useAppDispatch } from '../../store/hooks';

const routes = [
  {
    path: ROUTES.INVITE,
  },
];

export const MixPanel = (): null => {
  const { pathname } = useLocation();

  const [applicationName, setApplicationName] = useState('');

  const dispatch = useAppDispatch();

  const initialize = (id: string) => {
    mixpanel.init(id, { debug: MODE === 'local' });
  };

  const getPageLocationName = () => {
    const currentRoute = routes.find(({ path }) => path === pathname);

    let currentLocation = '';

    if (currentRoute) {
      const { path } = currentRoute;

      switch (path) {
        case ROUTES.INVITE:
          currentLocation = `${applicationName}.signup`;
          break;
        default:
          break;
      }
    } else if (pathname.includes('/login')) {
      currentLocation = `${applicationName}.login`;
    }

    return currentLocation;
  };

  useEffect(() => {
    if (MODE !== 'local') {
      initialize(MIX_PANEL_TOKEN);
      setApplicationName('signup');
      dispatch(initMixpanel());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (MODE !== 'local' && applicationName) {
      mixpanel.track(getPageLocationName());
    } // eslint-disable-next-line
  }, [pathname, applicationName]);

  return null;
};
