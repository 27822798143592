import React, { useState, useEffect, CSSProperties, useRef } from 'react';

import { ReactComponent as Chevron } from './assets/chevron-icon.svg';

interface RequiredItemProperties<T> {
  value: T;
  label: string;
}

interface CustomSelectProps<A, T extends RequiredItemProperties<A>> {
  label?: string;
  isRequired?: boolean;
  selectedItem?: T;
  items: T[];
  onChange?: (newValue: T) => void;
  placeholder?: string;
  icon?: string | null;
  style?: CSSProperties;
  margin?: string;
  padding?: string;
  justifyContentItems?: string;
  selectedItemJustify?: string;
  itemPadding?: string;
  disabled?: boolean;
  isData?: boolean;
  isError?: boolean;
}

function CustomSelect<A, T extends RequiredItemProperties<A>>(
  props: CustomSelectProps<A, T>
): JSX.Element {
  const {
    label,
    isRequired,
    selectedItem,
    items,
    onChange,
    placeholder,
    icon,
    style,
    margin,
    padding,
    justifyContentItems,
    selectedItemJustify,
    itemPadding,
    disabled,
    isData,
    isError,
  } = props;
  const [currentValue, setCurrentValue] = useState<T | null>(
    selectedItem || null
  );
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const refToCatchClickOutside = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (open && !refToCatchClickOutside.current?.contains(target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    if (!currentValue) {
      setCurrentValue(selectedItem || null);
    } // eslint-disable-next-line
  }, [selectedItem]);

  const updateValue = (newValue: T) => () => {
    setCurrentValue(newValue);
    if (onChange) onChange(newValue);
    toggleOpen();
  };

  const wrapperClassName = `${open ? 'up-index' : ''} mainBlock`;

  const optionsTopContainerClassName = !open
    ? 'closed mainBlock-dropped-overflow-container'
    : `mainBlock-dropped-overflow-container`;

  const optionsWrapperClassName = `mainBlock-dropped-block ${
    !open ? 'closed' : ''
  }`;

  const defineSelectsBorderColor = () => {
    let className = 'mainBlock-current-selected-item-container';

    if (isError) {
      className = 'error mainBlock-current-selected-item-container';
    } else if (open) {
      className = 'open mainBlock-current-selected-item-container';
    } else if (isData && !open) {
      className = 'warn mainBlock-current-selected-item-container';
    }

    return className;
  };

  const selectClassName = defineSelectsBorderColor();

  const droppedItemClassName = (el: T) => {
    let className = 'drop-item';

    if (el.label === 'Custom' || el.label === currentValue?.label) {
      className = 'disabled drop-item';
    } else if (el.label === 'All Users') {
      className = 'all-users drop-item';
    }

    return className;
  };

  const title = label?.length ? (
    <span className={`${isRequired ? 'required' : ''} mainBlock__label`}>
      {label}
    </span>
  ) : null;

  const selectedItemIcon = icon ? <img src={icon} alt="icon" /> : null;

  const selectedItemValue = (
    <div
      className={`${
        !currentValue?.label ? 'placeholder' : ''
      } mainBlock-current-selected-item-text`}
      style={{ padding, justifyContent: selectedItemJustify }}
    >
      {currentValue?.label || placeholder || null}
    </div>
  );

  const chevron = (
    <div className={`mainBlock-chevron-container  ${!open ? 'revert' : ''}`}>
      <Chevron />
    </div>
  );

  const mappedOptions = items.map((el) => (
    <div
      key={JSON.stringify(el)}
      className={droppedItemClassName(el)}
      onClick={updateValue(el)}
      style={{
        justifyContent: justifyContentItems,
        padding: itemPadding,
      }}
    >
      {el.label}
    </div>
  ));

  return (
    <div className={wrapperClassName} ref={refToCatchClickOutside}>
      {title}
      <div
        className={selectClassName}
        style={style}
        onClick={!disabled ? toggleOpen : undefined}
      >
        {selectedItemIcon}
        {selectedItemValue}
        {chevron}
      </div>
      <div className={optionsTopContainerClassName} style={{ margin }}>
        <div className={optionsWrapperClassName}>{mappedOptions}</div>
      </div>
    </div>
  );
}

export default CustomSelect;
