import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from './global.types';

export const initialState: GlobalState = {
  isMixpanelInitialized: false,
  isGoogleAnalyticsInitialized: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    initMixpanel: (state) => {
      state.isMixpanelInitialized = true;
    },
    initGoogleAnalytics: (state) => {
      state.isGoogleAnalyticsInitialized = true;
    },
  },
});

export const { initMixpanel, initGoogleAnalytics } = globalSlice.actions;

export default globalSlice.reducer;
