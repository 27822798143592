import {
  GoogleAuthProvider,
  User as FirebaseUser,
  signInWithPopup,
} from 'firebase/auth';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { FirebaseContextValue } from './firebase.types';
import { firebaseAuth } from './firebase.setup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  endSignIn,
  initAuth,
  startSignIn,
  throwSignInError,
  updateIsLoggedIn,
} from '../../store/auth/auth.slice';
import {
  getIdentity,
  signOut as signOutRequest,
} from '../../store/auth/auth.thunks';
import {
  ChromeMessage,
  ChromeMessageType,
} from '../../types/chromeMessage.types';
import {
  EXTENSION_ID,
  MINIMUM_EXTENSION_VERSION,
} from '../../constants/config';
import { ROUTES } from '../../routes';

const FirebaseContext = createContext<FirebaseContextValue>({
  user: null,
  isLoggedIn: false,
  isInitialized: false,
  isSignInLoading: false,
  isSignInError: false,
  googleSignIn: () => {},
  signOut: () => {},
});

export const FirebaseProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const isLoggedInValue = useAppSelector((state) => state.auth.isLoggedIn);
  const isInitialized = useAppSelector((state) => state.auth.isInitialized);
  const isSignInError = useAppSelector((state) => state.auth.isError.signIn);

  const isSignInLoading = useAppSelector(
    (state) => state.auth.isLoading.signIn
  );

  const isIdentityError = useAppSelector(
    (state) => state.auth.isError.identity
  );

  const extensionAuthCode = useAppSelector(
    (state) => state.auth.extensionAuthCode
  );

  const { pathname } = useLocation();

  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

  const isLoggedIn =
    isLoggedInValue && !isSignInLoading && !isSignInError && !isIdentityError;

  const isLogoutPage = pathname === ROUTES.LOGOUT;

  const runSignInFlow = useCallback(
    async (callback: () => Promise<unknown> | void) => {
      try {
        dispatch(startSignIn());
        await callback();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwSignInError());
        dispatch(updateIsLoggedIn(false));
        firebaseAuth.signOut();
      } finally {
        dispatch(endSignIn());
      }
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    try {
      await dispatch(signOutRequest()).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      dispatch(updateIsLoggedIn(false));
      firebaseAuth.signOut();
    }
  }, [dispatch]);

  const googleSignIn = useCallback(
    (email?: string) => {
      runSignInFlow(() => {
        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          access_type: 'offline',
          include_granted_scopes: 'true',
          ...(email ? { login_hint: email } : {}),
        });

        return signInWithPopup(firebaseAuth, provider);
      });
    },
    [runSignInFlow]
  );

  const firebaseContextValue = useMemo(
    () => ({
      user: firebaseUser,
      isLoggedIn,
      isInitialized,
      isSignInLoading,
      isSignInError,
      googleSignIn,
      signOut,
    }),
    [
      firebaseUser,
      isLoggedIn,
      isInitialized,
      isSignInLoading,
      isSignInError,
      googleSignIn,
      signOut,
    ]
  );

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (currentUser) => {
      setFirebaseUser(currentUser);
      dispatch(initAuth(!!currentUser));
    });

    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!isLogoutPage && !user && isLoggedIn) {
        try {
          await dispatch(getIdentity()).unwrap();
        } catch (error) {
          dispatch(updateIsLoggedIn(false));
          firebaseAuth.signOut();
        }
      }
    })();
  }, [dispatch, isLoggedIn, isLogoutPage, user]);

  useEffect(() => {
    if (extensionAuthCode && chrome?.runtime) {
      const message: ChromeMessage<ChromeMessageType.LOGIN> = {
        type: ChromeMessageType.LOGIN,
        payload: {
          authCode: extensionAuthCode,
          minimumExtensionVersion: MINIMUM_EXTENSION_VERSION,
        },
      };

      chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
        if (response) {
          // eslint-disable-next-line no-console
          console.log(response);
        } else {
          // eslint-disable-next-line no-console
          console.error(chrome.runtime.lastError);
        }
      });
    }
  }, [extensionAuthCode]);

  return (
    <FirebaseContext.Provider value={firebaseContextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContextValue =>
  useContext(FirebaseContext);
