import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { ROUTES } from './routes';
import {
  AddExtension,
  PaymentDetails,
  Pricing,
  Processing,
  Signup,
} from './pages';
import { SignupBackground } from './components';
import { useFirebase } from './context';

const routes = [
  {
    path: ROUTES.PRICING,
    isProtected: true,
    Component: Pricing,
  },
  {
    path: ROUTES.PAYMENT_DETAILS,
    isProtected: true,
    Component: PaymentDetails,
  },
  {
    path: ROUTES.PROCESSING,
    isProtected: true,
    Component: Processing,
  },
  {
    path: ROUTES.ADD_EXTENSION,
    isProtected: true,
    Component: AddExtension,
  },
  {
    path: ROUTES.INVITE,
    Component: Signup,
  },
  {
    path: ROUTES.HOME,
    Component: Signup,
  },
];

export const SignupRouter: React.FC = (): JSX.Element => {
  const { isLoggedIn, isInitialized } = useFirebase();

  const mappedRoutes = useMemo(
    () =>
      routes.map(({ path, Component, isProtected }) => {
        if (isProtected && isInitialized && !isLoggedIn) {
          return (
            <Route key={path} exact path={path}>
              <Redirect key={path} to={ROUTES.HOME} />;
            </Route>
          );
        }

        return (
          <Route key={path} exact path={path}>
            <Component />
          </Route>
        );
      }),
    [isInitialized, isLoggedIn]
  );

  return (
    <TransitionGroup>
      <SignupBackground>
        <Switch>{mappedRoutes}</Switch>
      </SignupBackground>
    </TransitionGroup>
  );
};
