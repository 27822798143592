export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  PRICING: '/signup/pricing',
  INVITE: '/orgs/:orgId/signup/:token',
  PAYMENT_DETAILS: '/signup/payment-details',
  PROCESSING: '/signup/processing',
  COMPLETE_ORDER: '/signup/complete-order',
  ORDER_COMPLETED: '/signup/order-completed',
  ADD_EXTENSION: '/signup/add-extension',
  THANK_YOU: '/signup/thank-you',
  Q_UNINSTALL: '/q-uninstall',
};
