import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import packageInfo from '../package.json';
import './App.scss';
import { store } from './store/store';
import { ROUTES } from './routes';
import { SignupRouter } from './SignupRouter';
import { FirebaseProvider } from './context';
import { Logout, SignupRedirect, UnistallExtension } from './pages';
import { ClientInterceptors, GoogleAnalytics, MixPanel } from './components';

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <FirebaseProvider>
          <ClientInterceptors />
          <div className="page">
            <div className="page__content">
              <Switch>
                <Route path={ROUTES.LOGOUT}>
                  <Logout />
                </Route>
                <Route path={ROUTES.Q_UNINSTALL}>
                  <UnistallExtension />
                </Route>
                <Route
                  path={[
                    ROUTES.HOME,
                    ROUTES.PRICING,
                    ROUTES.PAYMENT_DETAILS,
                    ROUTES.PROCESSING,
                    ROUTES.ADD_EXTENSION,
                    ROUTES.INVITE,
                  ]}
                  exact
                >
                  <SignupRouter />
                </Route>
                <Route path={[ROUTES.LOGIN, ROUTES.SIGNUP]}>
                  <SignupRedirect />
                </Route>
                <Route path="*">
                  <Redirect to={ROUTES.HOME} />
                </Route>
              </Switch>
              <div className="page__content-version">
                Ver {packageInfo.version}
              </div>
            </div>
          </div>
          <MixPanel />
          <GoogleAnalytics />
        </FirebaseProvider>
      </Provider>
    </Router>
  );
};

export default App;
