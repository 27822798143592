import React, { useLayoutEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import axios from '../../axios';
import { useFirebase } from '../../context';

export const ClientInterceptors: React.FC = () => {
  const dispatch = useAppDispatch();

  const { user } = useFirebase();

  useLayoutEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        try {
          if (user && config.headers) {
            const token = await user.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
        } catch (e) {
          return Promise.reject(e);
        }
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [dispatch, user]);

  return null;
};
