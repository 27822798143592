import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { SIGNUP_URL } from '../../../constants/config';
import {
  PaymentButton,
  PaymentButtonState,
} from './PaymentButton/PaymentButton';
import { RootState } from '../../../store/store';
import { ROUTES } from '../../../routes';
import { useAppSelector } from '../../../store/hooks';

export default function CheckoutForm({
  price,
}: {
  price: number;
}): JSX.Element {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const choosedPlan = useAppSelector((state) => state.auth.choosedPlan);
  const trialUsedStatus = useAppSelector((state) => state.auth.trialUsedStatus);

  const history = useHistory();

  const stripe = useStripe();

  const elements = useElements();

  const [errorState, setErrorState] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user === null || choosedPlan === null) {
      history.push(ROUTES.PRICING);
    }
  }, [user, choosedPlan, history]);

  // eslint-disable-next-line
  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorState(true);
  };

  // TODO: good practice to move out logic;
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    if (elements) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      // make a call, recieve type, and secret
      try {
        setLoading(true);

        const body = {
          productId: choosedPlan?.productId,
          priceId: choosedPlan?.priceId,
          quantity: choosedPlan?.seatsQty,
        };

        const response = await axios.post(
          `/services/billing/stripe/orgs/${user?.orgId}/users/${user?.userId}/subscription`,
          body
        );

        const { payload } = response.data.payload;

        const confirmIntent =
          payload?.type === 'setup'
            ? stripe.confirmSetup
            : stripe.confirmPayment;

        const { error } = await confirmIntent({
          elements,
          clientSecret: payload?.customerSecret,
          confirmParams: {
            return_url: `${SIGNUP_URL}/signup/processing`,
          },
        });

        if (error) {
          handleError(error);
          setLoading(false);
        }
      } catch (error) {
        setErrorState(true);
        setLoading(false);
      }
    }
  };

  const paymentButtonState = {
    state: PaymentButtonState.Default,
  };

  if (!stripe || !elements) {
    paymentButtonState.state = PaymentButtonState.Disabled;
  }

  if (loading) {
    paymentButtonState.state = PaymentButtonState.Loading;
  }

  return (
    <form
      onSubmit={handleSubmit}
      onClick={() => {
        if (errorState) setErrorState(false);
      }}
    >
      <PaymentElement />
      <div className="payment-form-footer">
        <div className="payment-form-footer-wrapper">
          <div>
            {trialUsedStatus ? null : (
              <p>
                Due today{' '}
                <span className="trial-info">(30-Day trial):&nbsp;</span>
                <span>$0</span>
              </p>
            )}
            <p>
              Recurring total:&nbsp;<span>${price}</span>
            </p>
          </div>
          <PaymentButton state={paymentButtonState.state}>
            Subscribe
          </PaymentButton>
        </div>
      </div>
    </form>
  );
}
