export enum ChromeMessageType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface ChromeMessagePayloadMap {
  [ChromeMessageType.LOGIN]: {
    authCode: string;
    minimumExtensionVersion: string;
  };
  [ChromeMessageType.LOGOUT]: null;
}

export interface ChromeMessage<T extends ChromeMessageType> {
  type: T;
  payload: ChromeMessagePayloadMap[T];
}
