import React, { useEffect, useRef, useState } from 'react';
import { excludedImg, includedImg } from './assets';
import { PaymentPeriod, PlanCard } from '../PlanCard/PlanCard';

const tableData = [
  {
    name: 'AI Message Wizard',
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'X-Ray Mode',
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Real-Time Response Simulator',
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Authority Analysis',
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Upper-Hand Analysis',
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Team Management',
    first: false,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Performance Analytics',
    first: false,
    second: true,
    third: true,
    fourth: true,
  },
  { name: 'Webhooks', first: false, second: true, third: true, fourth: true },
  {
    name: 'Prospect Data Enrichment',
    first: false,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Event Notification',
    first: false,
    second: true,
    third: true,
    fourth: true,
  },
  {
    name: 'Organization Management',
    first: false,
    second: false,
    third: true,
    fourth: true,
  },
  {
    name: 'CRM Integrations',
    first: false,
    second: false,
    third: true,
    fourth: true,
  },
  {
    name: 'Team Analytics',
    first: false,
    second: false,
    third: true,
    fourth: true,
  },
  {
    name: 'Workflow Integrations',
    first: false,
    second: false,
    third: true,
    fourth: true,
  },
  {
    name: 'Customer Success',
    first: false,
    second: false,
    third: true,
    fourth: true,
  },
  {
    name: 'Dedicated Customer Success',
    first: false,
    second: false,
    third: false,
    fourth: true,
  },
  {
    name: 'Custom Onboarding &Training',
    first: false,
    second: false,
    third: false,
    fourth: true,
  },
  {
    name: 'Custom Workflow Integrations',
    first: false,
    second: false,
    third: false,
    fourth: true,
  },
];

const mobVersionTableData = {
  starter: [
    'Team Members 1-3',
    'AI Message Wizard',
    'X-Ray Mode',
    'Real-Time Response Simulator',
    'Authority Analysis',
    'Upper-Hand Analysis',
    'Team Management',
  ],

  professional: [
    'Team Members 1-10',
    'Performance Analytics',
    'Webhooks',
    'Prospect Data Enrichment',
    'Event Notifications',
    'Organization management',
  ],

  scale: [
    'Team Members 11-30',
    'CRM Integrations',
    'Team Analytics',
    'Workflow Integrations',
    'Customer Success',
  ],

  enterprise: [
    'Dedicated Customer Success',
    'Custom Onboarding &Training',
    'Custom Workflow Integrations',
  ],
};

interface Props {
  isOpen: boolean;
  scrollUp: () => void;
  paymentPeriod: PaymentPeriod;
  isMobile?: boolean;
}

export const FeaturesTable: React.FC<Props> = ({
  isOpen = false,
  scrollUp = () => {},
  paymentPeriod = 'monthly',
  isMobile = false,
}): JSX.Element => {
  const [closed, setClosed] = useState(!isOpen);

  const elForScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timerToClose: NodeJS.Timeout;
    let timerToScrollTo: NodeJS.Timeout;

    if (isOpen) {
      setClosed(false);
      timerToScrollTo = setTimeout(() => {
        elForScrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else if (!closed) {
      timerToClose = setTimeout(() => {
        setClosed(true);
      }, 420);
    }

    return () => {
      clearTimeout(timerToClose);
      clearTimeout(timerToScrollTo);
    };
  }, [isOpen]); // eslint-disable-line

  const defineDataForButton = (planNumber: number) => {
    const className = `${
      planNumber === 2 ? 'recommended-p' : ''
    } plan-subscribe-button`;

    const style = { width: '70%', maxWidth: '140px', marginTop: '16px' };

    const action = () => {
      if (planNumber === 4) {
        window.open('https://www.substrata.me/contact', '_blank');
      } else {
        scrollUp();
      }
    };

    // if (user) {
    //   const { userId, orgId } = user;

    //   dispatch(setChoosedPlanInfo(productId, priceId, `${seatsQty}`));

    //   if (planNumber === 1) {
    //     action = () => {};
    //   }
    // }

    return { className, onClick: action, style };
  };

  const attrs = {
    wrapper: {
      className: `${closed ? 'hidden' : ''} features`,
    },

    scrollElement: {
      ref: elForScrollRef,
      className: `${isMobile ? 'mob' : ''} features-scroll-el`,
    },

    table: {
      wrapper: {
        className: 'features__table',
      },

      titleRow: {
        wrapper: {
          className: 'features__table__title-row',
        },

        cell: {
          wrapper: {
            className: 'features__table__title-row__cell',
          },

          button: (planNumber: number) => defineDataForButton(planNumber),
        },
      },

      featureRow: {
        wrapper: {
          className: 'features__table__feature-row',
        },

        name: {
          className: 'features__table__feature-row-name',
        },

        checkmark: {
          className: 'features__table__feature-row-checkmark',
        },
      },

      mobile: {
        planWrapper: {
          className: 'features__table__plan-mv',
        },

        title: {
          className: 'features__table__plan-mv-title',
        },

        subtitle: {
          className: 'features__table__plan-mv-subtitle',
        },

        feature: {
          className: 'features__table__plan-mv-feature',
        },
      },
    },
  };

  const createFeatureRow = (
    featureName = '',
    firstPlan = false,
    secondPlan = false,
    thirdPlan = false,
    fourthPlan = false
  ) => {
    const createCheckmarkCell = (flag: boolean) => {
      let result = (
        <div {...attrs.table.featureRow.checkmark}>
          <img src={includedImg} alt="" />
        </div>
      );

      if (!flag) {
        result = (
          <div {...attrs.table.featureRow.checkmark}>
            <img src={excludedImg} alt="" />
          </div>
        );
      }

      return result;
    };

    return (
      <div {...attrs.table.featureRow.wrapper}>
        <div {...attrs.table.featureRow.name}>{featureName}</div>
        {createCheckmarkCell(firstPlan)}
        {createCheckmarkCell(secondPlan)}
        {createCheckmarkCell(thirdPlan)}
        {createCheckmarkCell(fourthPlan)}
      </div>
    );
  };

  const titleRow = (
    <div {...attrs.table.titleRow.wrapper}>
      <div {...attrs.table.titleRow.cell.wrapper}>Compare plans</div>
      <div {...attrs.table.titleRow.cell.wrapper}>
        Starter
        <PlanCard
          currentPlan="starter"
          isInTable
          paymentPeriod={paymentPeriod}
        />
      </div>
      <div {...attrs.table.titleRow.cell.wrapper}>
        Professional
        <PlanCard
          currentPlan="professional"
          isInTable
          paymentPeriod={paymentPeriod}
        />
      </div>
      <div {...attrs.table.titleRow.cell.wrapper}>
        Scale
        <PlanCard currentPlan="scale" isInTable paymentPeriod={paymentPeriod} />
      </div>
      <div {...attrs.table.titleRow.cell.wrapper}>
        Enterprise
        <PlanCard
          currentPlan="enterprise"
          isInTable
          paymentPeriod={paymentPeriod}
        />
      </div>
    </div>
  );

  const mappedFeatureRows = tableData.map((el, i) => (
    <React.Fragment key={`${el}-${i}`}>
      {createFeatureRow(el.name, el.first, el.second, el.third, el.fourth)}
    </React.Fragment>
  ));

  const mapFeaturesMobileVer = (featuresArray: string[]) => {
    return (
      <div>
        {featuresArray.map((el, i) => (
          <div key={`${el}|${i}`} {...attrs.table.mobile.feature}>
            {el}
          </div>
        ))}
      </div>
    );
  };

  const mobileVersionTable = isMobile ? (
    <>
      <div {...attrs.table.mobile.planWrapper}>
        <div {...attrs.table.mobile.title}>Starter</div>
        {mapFeaturesMobileVer(mobVersionTableData.starter)}
      </div>
      <div {...attrs.table.mobile.planWrapper}>
        <div {...attrs.table.mobile.title}>Professional</div>
        <div {...attrs.table.mobile.subtitle}>Everything in Starter, plus:</div>
        {mapFeaturesMobileVer(mobVersionTableData.professional)}
      </div>
      <div {...attrs.table.mobile.planWrapper}>
        <div {...attrs.table.mobile.title}>Scale</div>
        <div {...attrs.table.mobile.subtitle}>
          Everything in Professional, plus:
        </div>
        {mapFeaturesMobileVer(mobVersionTableData.scale)}
      </div>
      <div {...attrs.table.mobile.planWrapper}>
        <div {...attrs.table.mobile.title}>Enterprise</div>
        <div {...attrs.table.mobile.subtitle}>Everything in Scale, plus:</div>
        {mapFeaturesMobileVer(mobVersionTableData.enterprise)}
      </div>
    </>
  ) : null;

  const table = isMobile ? (
    mobileVersionTable
  ) : (
    <>
      {titleRow}
      <div {...attrs.table.wrapper}>{mappedFeatureRows}</div>
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.scrollElement} />
      {table}
    </div>
  );
};
