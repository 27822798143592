import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const SignupRedirect: React.FC = () => {
  const { search } = useLocation();

  const redirectUrl = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const returnUrl = encodeURIComponent(queryParams.get('returnUrl') || '');
    const client = queryParams.get('client');

    return `${ROUTES.HOME}${returnUrl ? `?returnUrl=${returnUrl}` : ''}${
      client ? `${returnUrl ? '&' : '?'}client=${client}` : ''
    }`;
  }, [search]);

  const attrs = {
    redirect: {
      to: redirectUrl,
    },
  };

  return <Redirect {...attrs.redirect} />;
};
