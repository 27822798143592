import React, { useEffect } from 'react';
import {
  checkmark,
  chrome,
  mobileFlowBodyIcon,
  mobileFlowCheckmark,
  rocket,
  substrataLogo,
} from './assets';
import { ACCOUNT_URL } from '../../constants/config';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAuthCodes } from '../../store/auth/auth.thunks';
import { ClientType } from '../../store/auth/auth.types';

export const AddExtension: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const { trackEvent, isMixPanelInitialized } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.add-extension-page');
    }
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.add-extension-page');
    }
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  const goToDashboardWithWarn = async (withoutWarn?: boolean) => {
    if (user) {
      trackGAEvent(
        'event',
        'signup.add-extension-page.go-to-dashboard-clicked'
      );
      trackEvent('signup.add-extension-page.go-to-dashboard-clicked');

      const { dashboardAuthCode } = await dispatch(
        getAuthCodes({ client: ClientType.DASHBOARD })
      ).unwrap();

      window.location.href = `${ACCOUNT_URL}?authCode=${dashboardAuthCode}${
        withoutWarn ? '' : '&show-warning-popup=true'
      }`;
    }
  };

  const goToWebsite = () => {
    trackGAEvent('event', 'signup.add-extension-page.go-to-website-clicked');
    trackEvent('signup.add-extension-page.go-to-website-clicked');
    window.location.href = 'https://www.substrata.me/';
  };

  const openChromeStore = async () => {
    window.open(
      'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
      '_blank'
    );

    trackGAEvent(
      'event',
      'signup.add-extension-page.go-to-chrome-store-clicked'
    );
    trackEvent('signup.add-extension-page.go-to-chrome-store-clicked');

    if (user) {
      const { dashboardAuthCode } = await dispatch(
        getAuthCodes({ client: ClientType.DASHBOARD })
      ).unwrap();

      window.location.href = `${ACCOUNT_URL}?authCode=${dashboardAuthCode}`;
    }
  };

  const openContactCenter = () => {
    trackGAEvent('event', 'signup.add-extension-page.contact-us-clicked');
    trackEvent('signup.add-extension-page.contact-us-clicked');
    window.open('https://www.substrata.me/contact', '_blank');
  };

  const attrs = {
    wrapper: {
      className: 'ss-add-extension',
    },

    header: {
      wrapper: {
        className: 'ss-add-extension__header',
      },

      logo: {
        src: substrataLogo,
        className: 'ss-add-extension__header-logo',
      },
    },

    leftSection: {
      wrapper: {
        className: 'ss-add-extension__left-section',
      },

      header: {
        wrapper: {
          className: 'ss-add-extension__left-section__header',
        },

        title: {
          className: 'ss-add-extension__left-section__header-title',
        },

        subtitle: {
          className: 'ss-add-extension__left-section__header-subtitle',
        },
      },

      mobileHeader: {
        wrapper: {
          className: 'ss-add-extension__left-section__mobile-header',
        },

        title: {
          className: 'ss-add-extension__left-section__mobile-header-title',
        },

        subtitle: {
          className: 'ss-add-extension__left-section__mobile-header-subtitle',
        },

        successIcon: {
          className: 'ss-add-extension__left-section__mobile-header-icon',
          src: mobileFlowCheckmark,
        },
      },

      content: {
        wrapper: {
          className: 'ss-add-extension__left-section__content',
        },

        overflowWrapper: {
          className: 'ss-add-extension__left-section__content-overflow',
        },

        row: {
          wrapper: {
            className: 'ss-add-extension__left-section__content__row',
          },

          img: {
            className: 'ss-add-extension__left-section__content__row-img',
          },

          info: {
            wrapper: {
              className: 'ss-add-extension__left-section__content__row__info',
            },

            title: {
              className:
                'ss-add-extension__left-section__content__row__info-title',
            },

            subtitle: {
              className:
                'ss-add-extension__left-section__content__row__info-subtitle',
            },

            button: {
              className:
                'ss-add-extension__left-section__content__row__info-button',
              onClick: openChromeStore,
            },

            link: {
              className:
                'ss-add-extension__left-section__content__row__info-link',
              onClick: () => goToDashboardWithWarn(),
            },
          },
        },

        link: {
          className: 'ss-add-extension__left-section__content-link',
          onClick: openContactCenter,
        },
      },

      mobileContent: {
        wrapper: {
          className: 'ss-add-extension__left-section__mobile-content',
        },

        title: {
          className: 'ss-add-extension__left-section__mobile-content-title',
        },

        subtitle: {
          className: 'ss-add-extension__left-section__mobile-content-subtitle',
        },

        image: {
          className: 'ss-add-extension__left-section__mobile-content-img',
          src: mobileFlowBodyIcon,
        },
      },

      footer: {
        wrapper: {
          className: 'ss-add-extension__left-section__footer',
        },

        link: {
          className: 'ss-add-extension__left-section__footer-link',
          onClick: openContactCenter,
        },

        button: {
          className: 'ss-add-extension__left-section__footer-button',
          onClick: () => goToDashboardWithWarn(true),
        },
      },

      mobileFooter: {
        wrapper: {
          className: 'ss-add-extension__left-section__mobile-footer',
        },

        message: {
          className: 'ss-add-extension__left-section__mobile-footer-message',
        },

        button: {
          className: 'ss-add-extension__left-section__mobile-footer-button',
          onClick: goToWebsite,
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'ss-add-extension__right-section',
      },
    },
  };

  const mobileContent = (
    <>
      <div {...attrs.leftSection.mobileHeader.wrapper}>
        <div {...attrs.leftSection.mobileHeader.title}>
          Welcome to Substrata
        </div>
        <div {...attrs.leftSection.mobileHeader.subtitle}>
          Please visit substrata.me on your computer to continue setting up your
          account
        </div>
      </div>
      <div {...attrs.leftSection.mobileContent.wrapper}>
        <img {...attrs.leftSection.mobileContent.image} alt="" />
      </div>
      <div {...attrs.leftSection.mobileFooter.wrapper}>
        <div {...attrs.leftSection.mobileFooter.message}>
          To learn more, please visit our website
        </div>
        <div {...attrs.leftSection.mobileFooter.button}>Go to website</div>
      </div>
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header.wrapper}>
        <img {...attrs.header.logo} alt="" />
      </div>
      <div {...attrs.leftSection.wrapper}>
        <div {...attrs.leftSection.header.wrapper}>
          <div {...attrs.leftSection.header.title}>
            <span>Welcome, {user?.firstName || 'User'},</span>
            {'\n'} thanks for subscribing!
          </div>
          <div {...attrs.leftSection.header.subtitle}>
            Your subscription is now active. {'\n'} Next: Add Substrata Q to
            Your Chrome Browser.
          </div>
        </div>

        <div {...attrs.leftSection.content.wrapper}>
          <div {...attrs.leftSection.content.overflowWrapper}>
            <div {...attrs.leftSection.content.row.wrapper}>
              <img
                {...attrs.leftSection.content.row.img}
                src={checkmark}
                alt=""
              />
              <div {...attrs.leftSection.content.row.info.wrapper}>
                <div {...attrs.leftSection.content.row.info.title}>
                  Subscription confirmed
                </div>
                <div {...attrs.leftSection.content.row.info.subtitle}>
                  Your invoice will be sent to your email shortly
                </div>
              </div>
            </div>

            <div {...attrs.leftSection.content.row.wrapper}>
              <img {...attrs.leftSection.content.row.img} src={chrome} alt="" />
              <div {...attrs.leftSection.content.row.info.wrapper}>
                <div {...attrs.leftSection.content.row.info.title}>
                  Add Substrata to Chrome
                </div>
                <div {...attrs.leftSection.content.row.info.subtitle}>
                  Add Substrata Q extension to your Chrome web browser
                </div>
                <div {...attrs.leftSection.content.row.info.button}>
                  Add to Chrome
                </div>
                <div {...attrs.leftSection.content.row.info.link}>
                  No thanks, take me to the dashboard
                </div>
              </div>
            </div>

            <div {...attrs.leftSection.content.row.wrapper}>
              <img {...attrs.leftSection.content.row.img} src={rocket} alt="" />
              <div {...attrs.leftSection.content.row.info.wrapper}>
                <div {...attrs.leftSection.content.row.info.title}>
                  Explore Substrata Q
                </div>
              </div>
            </div>
          </div>
        </div>

        <div {...attrs.leftSection.footer.wrapper}>
          <span> Having Issues? Please</span>
          <div {...attrs.leftSection.footer.link}>Contact Us</div>
          <div {...attrs.leftSection.footer.button}>Go to homepage</div>
        </div>

        {mobileContent}
      </div>
      <div {...attrs.rightSection.wrapper} />
    </div>
  );
};
