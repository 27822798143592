import React from 'react';
import google from '../../assets/google.svg';

type ButtonProps = {
  children: string | JSX.Element;
  onClick?: () => void;
  isGoogleSignIn?: boolean;
  googleLightVariant?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  variant?: 'text' | 'filled';
  disabled?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  isGoogleSignIn = false,
  googleLightVariant = false,
  type = 'button',
  className = '',
  variant = 'filled',
  disabled,
}: ButtonProps): JSX.Element => (
  <button
    type={type}
    className={`button ${
      isGoogleSignIn ? 'button-google' : `button-ordinary-${variant}`
    } ${googleLightVariant ? 'light' : ''} ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {isGoogleSignIn ? (
      <>
        <img src={google} alt="Google Icon" className="button-google__icon" />
        <span
          className={`${
            googleLightVariant ? 'dark-text' : ''
          } button-google__text`}
        >
          {children}
        </span>
      </>
    ) : (
      <div className="button__children-text">{children}</div>
    )}
  </button>
);
