import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Button, ErrorModal } from '../../components';
import { linkChevron } from './assets';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { ACCOUNT_URL, SUPER_ACCOUNT_URL } from '../../constants/config';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useFirebase } from '../../context';
import { ClientType, UserSignUpFlow } from '../../store/auth/auth.types';
import { getAuthCodes, verifyInviteToken } from '../../store/auth/auth.thunks';
import { ROUTES } from '../../routes';

export const Signup: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const userSignUpFlow = useAppSelector((state) => state.auth.userSignUpFlow);

  const dashboardAuthCode = useAppSelector(
    (state) => state.auth.dashboardAuthCode
  );

  const superAdminAuthCode = useAppSelector(
    (state) => state.auth.superAdminAuthCode
  );

  const extensionAuthCode = useAppSelector(
    (state) => state.auth.extensionAuthCode
  );

  const {
    isLoggedIn,
    isInitialized,
    isSignInLoading,
    googleSignIn,
  } = useFirebase();

  const history = useHistory();
  const { search } = useLocation();
  const { orgId, token } = useParams<{ orgId: string; token: string }>();

  const { trackEvent } = useMixPanel();
  const { trackGAEvent } = useGoogleAnalytics();

  const { returnUrl, client } = useMemo(() => {
    const queryParams = new URLSearchParams(search);

    return {
      returnUrl: decodeURIComponent(queryParams.get('returnUrl') || ''),
      client: queryParams.get('client') as ClientType,
    };
  }, [search]);

  // Get auth codes for apps
  useEffect(() => {
    if (
      isLoggedIn &&
      userSignUpFlow &&
      userSignUpFlow !== UserSignUpFlow.SELF_SIGNUP &&
      userSignUpFlow !== UserSignUpFlow.INVITE_SIGNUP
    ) {
      dispatch(getAuthCodes({ client }));
    }
  }, [client, dispatch, isLoggedIn, userSignUpFlow]);

  // Verify invite token
  useEffect(() => {
    if (orgId && token) {
      dispatch(verifyInviteToken({ orgId, token }));
    }
  }, [orgId, token, dispatch]);

  // Handle redirects
  useEffect(() => {
    if (!user) {
      return;
    }

    if (
      userSignUpFlow === UserSignUpFlow.SELF_SIGNUP ||
      userSignUpFlow === UserSignUpFlow.INVITE_SIGNUP
    ) {
      history.push(ROUTES.PRICING);
      return;
    }

    if (superAdminAuthCode && (!returnUrl || returnUrl === SUPER_ACCOUNT_URL)) {
      window.location.href = `${SUPER_ACCOUNT_URL}?authCode=${superAdminAuthCode}`;
      return;
    }

    if (extensionAuthCode && returnUrl?.includes('mail.google.com')) {
      window.location.href = `https://mail.google.com/mail/u/${user.email}/#inbox`;
      return;
    }

    if (dashboardAuthCode) {
      window.location.href = `${ACCOUNT_URL}?authCode=${dashboardAuthCode}`;
    }
  }, [
    dashboardAuthCode,
    extensionAuthCode,
    history,
    returnUrl,
    superAdminAuthCode,
    user,
    userSignUpFlow,
  ]);

  const onGoogleSignIn = () => {
    if (token) {
      const { email } = jwtDecode<{ email: string }>(token);
      googleSignIn(email);
    } else {
      googleSignIn();
    }
  };

  const goToWebsite = () => {
    window.location.href = 'https://www.substrata.me/';
    trackEvent('signup.homepage.go-to-website-clicked');
    trackGAEvent('event', 'signup.homepage.go-to-website-clicked');
  };

  const openContactCenter = () => {
    window.open('https://www.substrata.me/contact', '_blank');
    trackEvent('signup.homepage.open-contact-center-clicked');
    trackGAEvent('event', 'signup.homepage.open-contact-center-clicked');
  };

  const openTermsOfUse = () => {
    window.open('https://www.substrata.me/terms-of-service/', '_blank');
    trackEvent('signup.homepage.open-terms-of-use-clicked');
    trackGAEvent('event', 'signup.homepage.open-terms-of-use-clicked');
  };

  const openPrivacyPolicy = () => {
    window.open('https://www.substrata.me/privacy-policy/', '_blank');
    trackEvent('signup.homepage.open-privacy-policy-clicked');
    trackGAEvent('event', 'signup.homepage.open-privacy-policy-clicked');
  };

  const attrs = {
    wrapper: {
      className: 'signup',
    },

    leftSection: {
      wrapper: {
        className: 'signup__left-section',
      },

      linkBack: {
        wrapper: {
          className: 'signup__left-section__link-back',
          onClick: goToWebsite,
        },

        chevron: {
          className: 'signup__left-section__link-back-chevron',
          src: linkChevron,
        },
      },

      content: {
        wrapper: {
          className: 'signup__left-section__content',
        },

        row: {
          className: 'signup__left-section__content-row',
        },

        google: {
          wrapper: {
            className: 'signup__left-section__content__google',
          },

          button: {
            className: 'signup__left-section__content__google-button',
            onClick: onGoogleSignIn,
            isGoogleSignIn: true,
            googleLightVariant: true,
            disabled: !isInitialized || isLoggedIn || isSignInLoading,
          },

          contact: {
            wrapper: {
              className: 'signup__left-section__content__google__contact',
            },

            text: {
              className: 'signup__left-section__content__google__contact-text',
            },

            link: {
              className: 'signup__left-section__content__google__contact-link',
              onClick: openContactCenter,
            },
          },
        },
      },

      footer: {
        wrapper: {
          className: 'signup__left-section__footer',
        },

        text: {
          wrapper: {
            className: 'signup__left-section__footer__text',
          },

          links: {
            termsOfUse: {
              className: 'signup__left-section__footer__text-link',
              onClick: openTermsOfUse,
            },

            privacyPolicy: {
              className: 'signup__left-section__footer__text-link',
              onClick: openPrivacyPolicy,
            },
          },
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'signup__right-section',
      },

      title: {
        className: 'signup__right-section-title',
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.leftSection.wrapper}>
        <div {...attrs.leftSection.linkBack.wrapper}>
          <img {...attrs.leftSection.linkBack.chevron} alt="" /> Back
        </div>

        <div {...attrs.leftSection.content.google.wrapper}>
          <Button {...attrs.leftSection.content.google.button}>
            Continue with Google
          </Button>
          <div {...attrs.leftSection.content.google.contact.wrapper}>
            <div {...attrs.leftSection.content.google.contact.text}>
              No Google account?
            </div>
            <div {...attrs.leftSection.content.google.contact.link}>
              Contact us
            </div>
          </div>
        </div>

        <div {...attrs.leftSection.footer.wrapper}>
          <div {...attrs.leftSection.footer.text.wrapper}>
            By signing up with Google, you agree to our{' '}
            <div {...attrs.leftSection.footer.text.links.termsOfUse}>
              Terms of Service
            </div>{' '}
            and{' '}
            <div {...attrs.leftSection.footer.text.links.privacyPolicy}>
              Privacy Policy
            </div>
          </div>
        </div>
      </div>
      <div {...attrs.rightSection.wrapper} />
      <ErrorModal />
    </div>
  );
};
