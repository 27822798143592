import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptionsMode, loadStripe } from '@stripe/stripe-js';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import CheckoutForm from './components/CheckoutForm';
import backChevron from './assets/Chevron.svg';
import enterpreneurIcon from './assets/enterpreneur-icon.svg';
import incIcon from './assets/Inc.svg';
import { ROUTES } from '../../routes';
import { STRIPE_PROMISE_ID } from '../../constants/config';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { substrataLogo } from './assets';
import { useAppSelector } from '../../store/hooks';

const stripePromise = loadStripe(STRIPE_PROMISE_ID);

export const PaymentDetails: React.FC = (): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user);
  const choosedPlan = useAppSelector((state) => state.auth.choosedPlan);

  const history = useHistory();

  const { trackEvent, isMixPanelInitialized } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.payment-details');
    }
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.payment-details');
    }
  }, [
    isMixPanelInitialized,
    isGoogleAnalyticsInitialized,
    trackGAEvent,
    trackEvent,
  ]);

  const planName = choosedPlan?.name;
  const planPrice = choosedPlan?.price ? +choosedPlan.price : 0;
  const numberOfSeats = choosedPlan?.seatsQty;
  const billingType = choosedPlan?.paymentPeriod;

  const StripeOptions: StripeElementsOptionsMode = {
    mode: 'subscription' as any,
    amount: planPrice * 100,
    currency: 'usd',
    setup_future_usage: 'off_session',
    // Enable only card support.
    paymentMethodTypes: ['card'],
    appearance: {
      theme: 'flat',
      variables: {
        colorBackground: '#FFFFFF',
        borderRadius: '8px',
      },
      rules: {
        '.Input': {
          border: '1px solid #CDD5E5',
          height: '40px',
        },
        '.Input::placeholder': {
          color: '#E0E2EE',
          fontFamily: 'Open sans, sans-serif',
          fontSize: '14px',
          fontWeight: '400',
        },
        '.Label': {
          fontFamily: 'Open sans, sans-serif',
          fontSize: '12px',
          fontWeight: '700',
          marginLeft: '8px',
        },
      },
    },
  };

  const attrs = {
    wrapper: {
      className: 'payment-details',
    },

    leftSection: {
      wrapper: {
        className: 'payment-details__left-section',
      },

      backButton: {
        wrapper: {
          className: 'payment-details__left-section__link-back',
          onClick: () => {
            history.push(ROUTES.PRICING);
          },
        },

        chevron: {
          className: 'payment-details__left-section__link-back-chevron',
          src: backChevron,
        },
      },

      logo: {
        className: 'payment-details__left-section-logo',
        src: substrataLogo,
      },
      paymentInfo: {
        className: 'payment-info',
      },
      paymentTitle: {
        className: 'payment-info-title',
      },
      paymentFields: {
        email: {
          className: 'payment-info-email',
        },
        billing: {
          className: 'payment-info-billing',
        },
        seats: {
          className: 'payment-info-seats',
        },
        fieldTitle: {
          className: 'payment-info-field-title',
        },
        fieldValue: {
          className: 'payment-info-field-value',
        },
      },
      footer: {
        className: 'payment-footer',
      },
      footerWrapper: {
        className: 'wrapper',
      },

      footerLinks: {
        termsOfService: {
          href: 'https://www.substrata.me/terms-of-service/',
          target: '_blank',
          rel: 'noopener noreferrer',
        },

        privacyPolicy: {
          href: 'https://www.substrata.me/privacy-policy/',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },

    rightSection: {
      wrapper: {
        className: 'payment-details__right-section',
      },

      slider: {
        wrapper: {
          className: 'payment-details__right-section__slider',
        },

        slide: {
          wrapper: {
            className: 'payment-details__right-section__slider__slide',
          },

          text: {
            className: 'payment-details__right-section__slider__slide-text',
          },

          icon: {
            className: 'payment-details__right-section__slider__slide-icon',
          },
        },
      },
    },
  };

  const slideSvgFirst = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="12"
      viewBox="0 0 88 12"
      fill="none"
    >
      <path
        d="M8.95076 5.80796C10.0587 5.59251 10.9819 4.54707 10.9819 3.07073C10.9745 1.47635 9.83893 0 7.56588 0H0.25L1.3856 1.47635V11.9681H7.82069C10.0956 11.9681 11.2663 10.5274 11.2663 8.72506C11.2238 7.24309 10.2212 5.9822 8.95076 5.80796ZM3.90794 2.24824H7.06916C7.92225 2.24824 8.45405 2.7541 8.45405 3.50913C8.45405 4.26417 7.92225 4.77002 7.06916 4.77002H3.90794V2.24824ZM7.17442 9.74239H3.90794V6.99016H7.17442C8.1346 6.99016 8.6664 7.60281 8.6664 8.35972C8.6664 9.22342 8.09767 9.72927 7.17442 9.72927V9.74239Z"
        fill="white"
      />
      <path
        d="M12.7148 11.9625H21.0611V9.72927H15.2372V6.99016H20.0658V4.75503H15.2372V2.23325H21.0962V0H12.7148V11.9625Z"
        fill="white"
      />
      <path
        d="M31.7794 7.52974L26.3119 0H22.5469L23.7194 1.51382V12H26.2399V4.14426L31.8865 11.9625H34.3018V0H31.7794V7.52974Z"
        fill="white"
      />
      <path
        d="M44.6318 2.0534V0H35.752V2.23325H41.3284L35.752 9.90913V11.9625H44.7389V9.72927H39.0203L44.6318 2.0534Z"
        fill="white"
      />
      <path d="M48.7118 0H46.1895V12H48.7118V0Z" fill="white" />
      <path
        d="M59.3965 7.52974L53.9271 0H50.1621L51.3346 1.51382V12H53.857V4.14426L59.5036 11.9625H61.917V0H59.3965V7.52974Z"
        fill="white"
      />
      <path
        d="M70.6111 7.52974H71.9258V8.93677C71.2458 9.508 70.3933 9.82541 69.5105 9.83606C67.4147 9.83606 65.9246 8.21545 65.9246 6.0178C65.9246 3.82014 67.4147 2.19766 69.5105 2.19766C70.0491 2.20218 70.5781 2.34299 71.0495 2.60733C71.5209 2.87167 71.9198 3.25118 72.2101 3.71147L74.1988 2.58735C73.3476 1.22529 71.8538 0 69.4754 0C66.137 0 63.3672 2.34192 63.3672 5.9822C63.3672 9.62248 66.1019 11.9625 69.4754 11.9625C70.3865 11.9779 71.29 11.7925 72.1236 11.4193C72.9573 11.046 73.7012 10.4937 74.3041 9.80047V5.36955H68.9067L70.6111 7.52974Z"
        fill="white"
      />
      <path
        d="M83.4243 0H78.5606L79.7313 1.51382L79.5189 2.08899L75.7539 12H78.5957L79.3343 9.98033H84.4214L85.16 12H88L83.4243 0ZM79.9824 7.71148L81.8289 2.55738L83.6754 7.71148H79.9824Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.leftSection.wrapper}>
        <div {...attrs.leftSection.backButton.wrapper}>
          <img {...attrs.leftSection.backButton.chevron} alt="" /> Back
        </div>
        <img {...attrs.leftSection.logo} alt="" />
        <div {...attrs.leftSection.paymentInfo}>
          <div {...attrs.leftSection.paymentTitle}>
            {`${planName} `}Plan <div /> <span>30-Day Trial</span>
          </div>
          <div {...attrs.leftSection.paymentFields.email}>
            <p {...attrs.leftSection.paymentFields.fieldTitle}>
              Account email:&nbsp;
              <span {...attrs.leftSection.paymentFields.fieldValue}>
                {user?.email}
              </span>
            </p>
          </div>
          <div {...attrs.leftSection.paymentFields.billing}>
            <p {...attrs.leftSection.paymentFields.fieldTitle}>
              Billing:&nbsp;
              <span {...attrs.leftSection.paymentFields.fieldValue}>
                {billingType}
              </span>{' '}
            </p>
          </div>
          <div {...attrs.leftSection.paymentFields.seats}>
            <p {...attrs.leftSection.paymentFields.fieldTitle}>
              Number of users:&nbsp;
              <span {...attrs.leftSection.paymentFields.fieldValue}>
                {numberOfSeats}
              </span>{' '}
            </p>
          </div>
        </div>
        <Elements stripe={stripePromise} options={StripeOptions}>
          <CheckoutForm price={planPrice} />
        </Elements>
        <div {...attrs.leftSection.footer}>
          <hr />
          <div {...attrs.leftSection.footerWrapper}>
            <p>
              <a {...attrs.leftSection.footerLinks.termsOfService}>
                Terms of Service
              </a>{' '}
              |{' '}
              <a {...attrs.leftSection.footerLinks.privacyPolicy}>
                {' '}
                Privacy Policy
              </a>
            </p>
            <p>
              Powered by <span> Stripe</span>
            </p>
          </div>
        </div>
      </div>
      <div {...attrs.rightSection.wrapper}>
        <div {...attrs.rightSection.slider.wrapper}>
          <div
            {...attrs.rightSection.slider.slide.wrapper}
            style={{ animationDelay: '0s' }}
          >
            <div {...attrs.rightSection.slider.slide.text}>
              Sales used to be an art – now, thanks to AI, it&apos;s a science.
              Substrata is leveraging the capabilities of deep learning to take
              things a very big step further.
            </div>
            <div {...attrs.rightSection.slider.slide.icon}>{slideSvgFirst}</div>
          </div>
          <div
            {...attrs.rightSection.slider.slide.wrapper}
            style={{ animationDelay: '8s' }}
          >
            <div {...attrs.rightSection.slider.slide.text}>
              Want to Improve Your Sales? It&apos;s time to place a heightened
              focus on nonverbal cues that words alone cannot express.
            </div>
            <div {...attrs.rightSection.slider.slide.icon}>
              <img src={incIcon} alt="" />
            </div>
          </div>
          <div
            {...attrs.rightSection.slider.slide.wrapper}
            style={{ animationDelay: '16s' }}
          >
            <div {...attrs.rightSection.slider.slide.text}>
              Substrata reads between the lines, evaluating prospects and
              guiding users to maximize their chances of success.
            </div>
            <div {...attrs.rightSection.slider.slide.icon}>
              <img src={enterpreneurIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
