import mixpanel from 'mixpanel-browser';
import { useAppSelector } from '../../store/hooks';

type ReturnType = {
  trackEvent: (name: string) => void;
  isMixPanelInitialized: boolean;
};

export const useMixPanel = (): ReturnType => {
  const isMixpanelIntialized = useAppSelector(
    (state) => state.global.isMixpanelInitialized
  );

  const user = useAppSelector((state) => state.auth.user);

  const trackEvent = (eventName: string) => {
    const checkedEventName = eventName.length ? eventName : '';

    if (isMixpanelIntialized) {
      try {
        if (user) {
          const { userId, orgId } = user;

          mixpanel.identify(`${orgId}|${userId}`);

          mixpanel?.people.set({
            name: user?.name,
            firstName: user?.firstName,
            lastName: user?.lastName,
            orgId: user?.orgId,
            userId: user?.userId,
          });
        }

        mixpanel?.track(checkedEventName);

        // eslint-disable-next-line
      } catch (error) {
        // catch crash if mixpanel is not initialized
      }
    }
  };

  return {
    trackEvent,
    isMixPanelInitialized: isMixpanelIntialized,
  };
};
