import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { CustomSelect } from '../../../../components';
import { getPlansData } from '../../utils/plansData.utils';
import { useMixPanel } from '../../../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../../../components/GoogleAnalytics/useGoogleAnalytics';
import { updateChoosedPlan } from '../../../../store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export type PlanVariant = 'starter' | 'professional' | 'scale' | 'enterprise';

export type PaymentPeriod = 'monthly' | 'yearly';

interface Props {
  currentPlan: PlanVariant;
  paymentPeriod?: PaymentPeriod;
  isInTable?: boolean;
}

export const PlanCard: React.FC<Props> = ({
  currentPlan = 'scale',
  paymentPeriod = 'monthly',
  isInTable = false,
}): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user);

  const plansAndPricing = useAppSelector((state) => state.auth.plansAndPricing);

  const initianNumberOfSeats = currentPlan === 'scale' ? 11 : 1;

  const [seatsQty, setSeatsQty] = useState<number>(initianNumberOfSeats);

  const [localPaymentPeriod, setLocalPaymentPeriod] = useState<PaymentPeriod>(
    'monthly'
  );

  const [isAnimation, setAnimation] = useState(false);

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { trackEvent } = useMixPanel();

  const { trackGAEvent } = useGoogleAnalytics();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (paymentPeriod.length && paymentPeriod !== localPaymentPeriod) {
      setLocalPaymentPeriod(paymentPeriod);
      setAnimation(true);

      timer = setTimeout(() => {
        setAnimation(false);
      }, 300);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [paymentPeriod]); // eslint-disable-line

  const defineDataToShow = (variant: PlanVariant, period: PaymentPeriod) => {
    let name = 'Enterprise';
    let productId = '';
    let priceId = '';
    let price: string | null = null;
    let priceText: string | null = 'Per user/month Billed monthly';
    let description = 'Custom requirements & more';
    let maxSeats: number | null = null;
    let featuresTitle = 'Everything in Scale, plus:';
    let features = [
      'Dedicated Customer Success',
      'Custom Onboarding &Training',
      'Custom Workflow Integrations',
    ];

    // to do by Object[key]

    const { starter, professional, scale } = getPlansData(plansAndPricing);

    if (variant === 'starter') {
      name = starter.name;
      productId = starter.productId;
      priceId =
        period === 'monthly'
          ? starter.price.monthly.priceId
          : starter.price.yearly.priceId;
      price =
        period === 'monthly'
          ? starter.price.monthly.price
          : starter.price.yearly.price;
      priceText =
        period === 'monthly'
          ? starter.priceText.monthly
          : starter.priceText.yearly;
      description = starter.description;
      maxSeats = starter.maxSeats;
      featuresTitle = starter.featuresTitle;
      features = starter.features;
    } else if (variant === 'professional') {
      name = professional.name;
      productId = professional.productId;
      priceId =
        period === 'monthly'
          ? professional.price.monthly.priceId
          : professional.price.yearly.priceId;
      price =
        period === 'monthly'
          ? professional.price.monthly.price
          : professional.price.yearly.price;
      priceText =
        period === 'monthly'
          ? professional.priceText.monthly
          : professional.priceText.yearly;
      description = professional.description;
      maxSeats = professional.maxSeats;
      featuresTitle = professional.featuresTitle;
      features = professional.features;
    } else if (variant === 'scale') {
      name = scale.name;
      productId = scale.productId;
      priceId =
        period === 'monthly'
          ? scale.price.monthly.priceId
          : scale.price.yearly.priceId;
      price =
        period === 'monthly'
          ? scale.price.monthly.price
          : scale.price.yearly.price;
      priceText =
        period === 'monthly' ? scale.priceText.monthly : scale.priceText.yearly;
      description = scale.description;
      maxSeats = scale.maxSeats;
      featuresTitle = scale.featuresTitle;
      features = scale.features;
    }

    return {
      name,
      productId,
      priceId,
      price,
      priceText,
      description,
      maxSeats,
      featuresTitle,
      features,
    };
  };

  const {
    name,
    productId,
    priceId,
    price,
    priceText,
    description,
    maxSeats,
    featuresTitle,
    features,
  } = defineDataToShow(currentPlan, paymentPeriod);

  const isCurrentPlanScaleCheck = currentPlan === 'scale';

  const objectsToCreateQty = isCurrentPlanScaleCheck ? 20 : maxSeats;

  const numberToAdd = isCurrentPlanScaleCheck ? 11 : 1;

  // eslint-disable-next-line
  const array = new Array(objectsToCreateQty).fill(0).map((el, i) => ({
    key: `${el}${i}`,
    label: `${i + numberToAdd} ${i + numberToAdd === 1 ? 'user' : 'users'}`,
    value: `${i + numberToAdd}`,
  }));

  const updateSelectValue = (newValue: typeof array[0]) => {
    setSeatsQty(+newValue.value);
  };

  const choosePlan = () => {
    if (currentPlan === 'enterprise') {
      window.open('https://www.substrata.me/contact', '_blank');
    } else if (user && price) {
      // const { userId, orgId } = user;

      dispatch(
        updateChoosedPlan({
          productId,
          priceId,
          price,
          seatsQty: `${seatsQty}`,
          paymentPeriod,
          name,
        })
      );
      // dispatch(
      //   callCreateSubscription(orgId, userId, productId, priceId, `${seatsQty}`)
      // );
      trackEvent('signup.pricing-page.subscribe-clicked');
      trackGAEvent('event', 'signup.pricing-page.subscribe-clicked');

      history.push(ROUTES.PAYMENT_DETAILS);
    }
  };

  const attrs = {
    wrapper: {
      className: `${currentPlan === 'professional' ? 'recommended-p' : ''} ${
        currentPlan === 'enterprise' ? 'custom' : ''
      } plan`,
    },

    mostPopular: {
      className: 'plan-most-popular',
      style: currentPlan === 'professional' ? {} : { display: 'none' },
    },

    planName: {
      className: 'plan-name',
    },

    price: {
      wrapper: {
        className: `${isAnimation ? 'animation' : ''} plan__price`,
      },

      dollar: {
        className: `${
          currentPlan === 'professional' ? 'recommended-p' : ''
        } plan__price-dollar`,
      },

      value: {
        className: `${
          currentPlan === 'professional' ? 'recommended-p' : ''
        } plan__price-value`,
      },

      text: {
        className: 'plan__price-text',
      },
    },

    description: {
      className: 'plan-description',
    },

    selectSection: {
      wrapper: {
        className: 'plan__select',
      },

      Select: {
        // className: 'plan__select',
        items: array,
        selectedItem: array[0],
        onChange: updateSelectValue,
      },
    },

    subscribe: {
      className: `${
        currentPlan === 'professional' ? 'recommended-p' : ''
      } plan-subscribe-button`,
      style: currentPlan === 'enterprise' ? {} : {},
      onClick: choosePlan,
    },

    submitButton: {
      className: 'plan-submit-button',
    },

    planFeaturesInfo: {
      wrapper: {
        className: 'plan__features-info',
      },

      title: {
        className: 'plan__features-info-title',
      },

      subtitle: {
        className: 'plan__features-info-subtitle',
      },

      features: {
        wrapper: {
          className: 'plan__features-info__features',
        },

        featureRow: {
          className: 'plan__features-info__features-row',
        },
      },
    },
  };

  const mappedFeatures = features.map((el, i) => (
    <div key={`${el}${i}`} {...attrs.planFeaturesInfo.features.featureRow}>
      {el}
    </div>
  ));

  const priceSectionToShow =
    currentPlan === 'enterprise' ? (
      <div {...attrs.price.wrapper}>
        <div {...attrs.price.value}> Custom </div>
        <div {...attrs.price.text}>{priceText}</div>
      </div>
    ) : (
      <div {...attrs.price.wrapper}>
        <div {...attrs.price.dollar}>$</div>
        <div {...attrs.price.value}>{price}</div>
        <div {...attrs.price.text}>{priceText}</div>
      </div>
    );

  const conditionalButtonText =
    currentPlan === 'enterprise' ? 'Talk to Sales' : 'Subscribe';

  const conditionalSelect =
    currentPlan === 'enterprise' ? (
      <></>
    ) : (
      <CustomSelect {...attrs.selectSection.Select} />
    );

  const conditionalBody = isInTable ? (
    <div {...attrs.subscribe}>{conditionalButtonText}</div>
  ) : (
    <div {...attrs.wrapper}>
      <div {...attrs.mostPopular}>Most Popular</div>
      <div {...attrs.planName}>{name}</div>
      {priceSectionToShow}
      <div {...attrs.description}>{description}</div>
      <div {...attrs.subscribe}>{conditionalButtonText}</div>
      <div {...attrs.selectSection.wrapper}>{conditionalSelect}</div>
      <div {...attrs.planFeaturesInfo.wrapper}>
        <div {...attrs.planFeaturesInfo.title}>{featuresTitle}</div>
        <div {...attrs.planFeaturesInfo.features.wrapper}>{mappedFeatures}</div>
      </div>
    </div>
  );

  return conditionalBody;
};
