import React, { useEffect } from 'react';
import ReactGa4 from 'react-ga4';
import { GA4_MEASUREMENT_ID } from '../../constants/config';
import { initGoogleAnalytics } from '../../store/global/global.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const GoogleAnalytics: React.FC = (): null => {
  const isGoogleAnalyticsInitialized = useAppSelector(
    (state) => state.global.isGoogleAnalyticsInitialized
  );

  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isGoogleAnalyticsInitialized) {
      ReactGa4.initialize(GA4_MEASUREMENT_ID);
      dispatch(initGoogleAnalytics());
    }
  }, [isGoogleAnalyticsInitialized, dispatch]);

  useEffect(() => {
    if (user) {
      const { userId, orgId } = user;

      ReactGa4.set({
        org_Id: orgId,
        user_Id: userId,
      });
    }
    // eslint-disable-next-line
  }, [user?.orgId, user?.userId]);

  return null;
};
