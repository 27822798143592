import { configureStore } from '@reduxjs/toolkit';
import { MODE } from '../constants/config';
import authReducer from './auth/auth.slice';
import globalReducer from './global/global.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
  },
  devTools: MODE === 'local',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
